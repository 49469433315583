@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
*{
  font-family: 'Open Sans', sans-serif;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media only screen and (max-width: 600px) {
  .logo {
    width: 130px;
  }

  #heading {
    font-size: 13pt;
  }

  #dateText {
    font-size: 8pt;
  }
}

@media only screen and (min-width: 1200px) {
  .logo {
    width: 230px;
    font-size: xx-large;
  }

  #heading {
    font-size: 24pt;
  }

  #dateText {
    font-size: 13pt;
  }
}