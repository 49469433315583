@media(max-width:976px) {
  .responsive-footer>div p {
    font-size: 12pt !important;
  }

  .responsive-footer>div {
    min-height: 25px !important;
  }
}

@media(max-width:786px) {
  .responsive-header {
    flex-wrap: wrap !important;
  }

  .responsive-header>div {
    width: 100% !important;
  }

  .responsive-header>div:first-child {
    width: 50% !important;
    order: 0 !important;
  }

  .responsive-header>div:last-child {
    width: 50% !important;
    display: flex;
    justify-content: flex-end;
    order: 1 !important;
  }

  .responsive-header>div:last-child p {
    font-size: 16pt !important;
  }

  .responsive-header>div:nth-child(2) {
    width: 100% !important;
    order: 2 !important;
    padding-top: 0 !important;
  }

  .responsive-footer {
    flex-wrap: wrap !important;
    height: 100% !important;
    flex-direction: column !important;
    justify-content: flex-end !important;
  }

  .responsive-footer>div {
    width: 100% !important;
  }

}

@media (max-width:400px) {
  .responsive-header>div:nth-child(2) p {
    font-size: 20pt !important;
  }
}

.slider {
  /* position: absolute; */
  list-style-type: none;
  text-align: center;
  animation: slider linear infinite;
  /* animation-duration: 60s */
}


@keyframes slider {
  0% {
    transform: translateY(217px)
  }

  100% {
    transform: translateY(-100%)
  }
}

#btn-shine {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  padding: 12px 48px;
  color: #ffffff;
  background-position: 0;
  -webkit-text-fill-color: transparent;
  animation: shine 3s infinite linear;
  animation-fill-mode: forwards;
  -webkit-text-size-adjust: none;
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
  white-space: nowrap;
}

@keyframes shine{
  0%{
    background-position: 0
  }
  60%{
    background-position: 180px
  }
  100%{
    background-position: 180px
  }
}